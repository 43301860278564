<template lang="pug">
  main
    HeaderWhite(ref="headerwhite")
    b-container#main-container
      b-modal(
        id="modalformex" @hidden="formex={}" hide-footer
      )
        h2 Aggiungi un nuovo foglio d'esercizio
        el-form(
          :rules="rulesex" label-width="90px" ref="formex" :model="formex"
        )
          span.asterisk I campi seguiti da <code>*</code> sono obbligatori
          FormInput(
            label="Data"
            :model="formex.date" :datepicker="true"
            v-model="formex.date" name="date"
          )
          FormInput(
            label="Dipendenti" name="employees"
            :model="formex.employees" v-model="formex.employees"
            :options="employees" :multiselect="true"
          )
          FormInput(
            label="Veicoli" name="vehicles"
            :model="formex.vehicles" v-model="formex.vehicles"
            :options="vehicles" :multiselect="true"
          )
          FormInput(
            label="Note" :text="true"
            :model="formex.note"
            v-model="formex.note" name="note"
          )
          b-button(variant="primary" @click="onSubmitEx") Salva

      b-modal(
        id="modalform" @hidden="form={}" hide-footer
        @close="handleClose" @hide="handleClose"
      )
        el-form(:rules="rulesop" ref="formop" :model="form")
          span.asterisk I campi seguiti da <code>*</code> sono obbligatori
          b-row
            b-col(md="10" sm="12")
              FormInput(
                label="Foglio d'esercizio" name="fe"
                :model="form.fe" v-model="form.fe"
                :options="exercisesheets"
              )
            b-col(md="2" sm="12")
              b-button.add-b-b(variant="success" block v-b-modal.modalformex)
                b-icon-plus

          FormInput(
            label="Orario" :model="form.hour" :timepicker="true"
            v-model="form.hour" name="hour"
          )

          FormInput(
            label="Note" :text="true"
            :model="form.note" v-model="form.note" name="note"
          )
          b-button(
            type="button" variant="primary" @click="onSubmit(1)"
          ) Salva
      div
        AdminSidebar
        aside._body
          h3 Informazioni sulla richiesta d'intervento
          el-form(
            :rules="rules" ref="operation" :model="operation"
          )
            span.asterisk I campi seguiti da <code>*</code> sono obbligatori
            b-row
              b-col(sm="12" md="3")
                FormInput(
                  label="Scadenza" v-model="operation.due" name="due"
                  :model="operation.due" :datepicker="true" :timepicker="true"
                )
              b-col(sm="12" md="3")
                FormInput(
                  label="Punto vendita" name="store" :options="stores"
                  :model="operation.store" v-model="operation.store"
                )
              b-col(sm="12" md="3")
                FormInput(
                  label="Tipologia di richiesta" name="type"
                  :model="operation.type" v-model="operation.type"
                  :options="requests_type"
                )
              b-col(sm="12" md="3")
                FormInput(
                  label="Stato di richiesta" name="status"
                  :model="operation.status" v-model="operation.status"
                  :options="requests_status"
                )
              b-col(cols="12")
                FormInput(
                  label="Note" :text="true"
                  :model="operation.note" v-model="operation.note" name="note"
                )

              b-col(cols="12")
                b-row(v-if="operation.type == 'EX' && operation.status == 'CL'")
                  b-col(cols="6")
                    FormInput(
                      label="ID" :model="operation.invoice_id"
                      v-model="operation.invoice_id" name="invoice_id"
                    )
                  b-col(cols="6")
                    FormInput(
                      label="Importo" :model="operation.invoice_amount"
                      v-model="operation.invoice_amount" name="invoice_amount"
                      :number="true"
                    )
              b-col(cols="12")
                b-button(variant="primary" @click="onSubmit(0)") Salva
          hr
          h3 Storico interventi
          b-nav#header
            b-button(v-b-modal.modalform variant="primary")
              | Nuovo intervento
            #right
              b-input(placeholder="Filtra" v-model="filter")
          b-table(
              responsive hover :items="operations" :fields="fields"
              :filter="filter"
          )
            template(#cell(date)="data")
              span {{ formate_date(data.item.date+' '+data.item.hour) }}
            template(#cell(employees)="data")
              span {{ data.item.employees.map(e => e.name).join(', ') }}
            template(#cell(vehicles)="data")
              span {{ data.item.vehicles.map(v => v.license_plate).join(', ') }}
            template(#cell(details)="row")
              b-button(
                size="sm" class="mr-2" variant="light" v-b-modal.modalform
                @click="handleEdit(row.item, row.index)"
              )
                b-icon-pencil
              b-button(
                size="sm" variant="danger"
                @click="handleDelete(row.index)"
              )
                b-icon-trash

          b-button(
            @click="go_back"
            style="margin: 50px auto;"
          ) Torna indietro
</template>

<script>
import HeaderWhite from "@/components/header-white.vue";
import AdminSidebar from "@/components/admin-sidebar.vue";
import FormInput from "@/components/form-input.vue";

export default {
  name: "OperationsDetails",
  props: ["id"],
  components: {
    HeaderWhite,
    AdminSidebar,
    FormInput
  },
  data() {
    return {
      operation: {}, // data from /operation-requests/<id>/
      operations: [], // data from /operation-requests/<id>/operations/
      stores: [],
      vehicles: [],
      employees: [],
      exercisesheets: [],
      form: {},
      formex: {}, // form for new exercise sheet
      temp_form: {
        index: null,
        data: {}
      },
      fields: [
        { key: "id", label: "ID" },
        { key: "date", label: "Creato" },
        { key: "fe.id", label: "FE" },
        { key: "note", label: "Note" },
        { key: "employees", label: "Dipendenti" },
        { key: "vehicles", label: "Veicoli" },
        { key: "details", label: "" }
      ],
      requests_type: [
        { value: "FO", text: "Forfait" },
        { value: "EX", text: "Extraforfait" }
      ],
      requests_status: [
        { value: "OP", text: "Aperta" },
        { value: "WA", text: "In attesa" },
        { value: "CL", text: "Chiusa" },
        { value: "CA", text: "Annullata" }
      ],
      filter: "",
      rules: {
        store: [{ required: true, message: "Il punto vendita è obbligatorio" }],
        type: [{ required: true, message: "La tipologia è obbligatoria" }],
        status: [{ required: true, message: "Lo stato è obbligatorio" }]
      },
      rulesex: {
        date: [{ required: true, message: "La data è obbligatoria" }]
      },
      rulesop: {
        hour: [{ required: true, message: "L'orario è obbligatorio" }],
        fe: [
          { required: true, message: "Il foglio d'esercizio è obbligatorio" }
        ]
      }
    };
  },
  mounted() {
    this.access_token = this.$cookies.get("access_token");
    let route = `${process.env.VUE_APP_BACKEND_URL}/operation-requests/${this.$props.id}/`;
    fetch(route, {
      headers: {
        Authorization: `Bearer ${this.access_token}`
      }
    }).then(async response => {
      this.operation = await response.json();
    });

    route += "operations/?limit=100";
    fetch(route, {
      headers: {
        Authorization: `Bearer ${this.access_token}`
      }
    }).then(async response => {
      const operations = await response.json();
      this.operations = operations.results;
    });

    fetch(`${process.env.VUE_APP_BACKEND_URL}/stores/?limit=1000`, {
      headers: {
        Authorization: `Bearer ${this.access_token}`
      }
    }).then(async response => {
      const stores = await response.json();
      for (const store of stores.results) {
        this.stores.push({
          text: `${store.pv} - ${store.city} (${store.province}), ${store.region}`,
          value: store.pv
        });
      }
    });

    fetch(`${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/?limit=100`, {
      headers: {
        Authorization: `Bearer ${this.access_token}`
      }
    }).then(async response => {
      const sheets = await response.json();
      for (const sheet of sheets.results) {
        this.exercisesheets.push({
          text: `${sheet.id} - ${sheet.date}`,
          value: sheet.id
        });
      }
    });

    fetch(`${process.env.VUE_APP_BACKEND_URL}/vehicles/?limit=1000`, {
      headers: {
        Authorization: `Bearer ${this.access_token}`
      }
    }).then(async response => {
      const vehicles = await response.json();
      for (const vehicle of vehicles.results) {
        this.vehicles.push({
          text: vehicle.license_plate,
          value: vehicle.id
        });
      }
    });

    fetch(`${process.env.VUE_APP_BACKEND_URL}/employees/?limit=1000`, {
      headers: {
        Authorization: `Bearer ${this.access_token}`
      }
    }).then(async response => {
      const employees = await response.json();
      for (const employee of employees.results) {
        this.employees.push({
          text: employee.name,
          value: employee.id
        });
      }
    });
  },
  methods: {
    go_back() {
      window.location.href = "/operations";
    },
    formate_date(text) {
      const _date = this.$moment(text, "YYYY-MM-DD hh:mm:ss");
      return _date.format("DD/MM/YYYY HH:mm");
    },
    handleEdit(data, index) {
      this.temp_form.index = index;
      this.temp_form.data = JSON.parse(JSON.stringify(data));
      this.form = data;
      this.form.employees = data.employees.map(x => x.id);
      this.form.vehicles = data.vehicles.map(x => x.id);
      this.form.fe = data.fe.id;
    },
    handleClose() {
      if (this.temp_form.index != null) {
        this.$set(this.operations, this.temp_form.index, this.temp_form.data);
        this.temp_form = {
          index: null,
          data: {}
        };
      }
    },
    onSubmitEx() {
      this.$refs["formex"].validate(valid => {
        if (valid) {
          let path = `${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/`;
          const action = {
            method: "POST",
            url: path,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`
            }
          };

          this.$refs.headerwhite.loading = true;
          this.formex.date = this.$moment(
            this.formex.date,
            "YYYY-MM-DDTHH:mm:ss.SSS"
          ).format("YYYY-MM-DD");
          fetch(action.url, {
            method: action.method,
            headers: action.headers,
            body: JSON.stringify(this.formex)
          })
            .then(async r => {
              const st = await r.status;
              if (st == 201) {
                this.$bvToast.toast(
                  "Foglio d'esercizio inserito con successo",
                  {
                    title: "Modifica effettuata",
                    variant: "success",
                    solid: true
                  }
                );
                const data = await r.json();

                this.exercisesheets.push({
                  text: `${data.id} - ${data.date}`,
                  value: data.id
                });
                this.form.fe = data.id;
                this.$bvModal.hide("modalformex");
                this.formex = {};
              } else {
                this.$bvToast.toast(`Errore ${st}`, {
                  title: "Foglio d'esercizio non inserito",
                  variant: "danger",
                  solid: true
                });
              }
            })
            .catch(e => {
              this.$bvToast.toast(e.status, {
                title: "Foglio d'esercizio non inserito",
                variant: "danger",
                solid: true
              });
            })
            .finally(() => (this.$refs.headerwhite.loading = false));
        } else {
          return false;
        }
      });
    },
    async get_exercise(id) {
      await fetch(`${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/${id}/`, {
        headers: {
          Authorization: `Bearer ${this.access_token}`
        }
      }).then(async response => {
        const data = await response.json();
        this.form.date = data.date;
        if (data.foreman) {
          this.form.employees = [data.foreman.id, ...data.employees.map(x => x.id)]
        } else {
          this.form.employees = data.employees.map(x => x.id);
        }
        this.form.vehicles = data.vehicles.map(x => x.id);
      });
    },
    onSubmit(operation) {
      let _m = operation ? "formop" : "operation";
      this.$refs[_m].validate(async valid => {
        if (valid) {
          let text = "Richiesta d'intervento";
          let path = `${process.env.VUE_APP_BACKEND_URL}/operation-requests/${this.$props.id}/`;
          let payload = this.operation;
          if (operation) {
            await this.get_exercise(this.form.fe);
            text = "Intervento";
            path += "operations/";
            payload = this.form;
            if (this.form.id) {
              path += this.form.id + "/";
            }
          }

          const action = {
            method: !operation || this.form.id ? "PUT" : "POST",
            url: path,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`
            }
          };

          this.$refs.headerwhite.loading = true;
          fetch(action.url, {
            method: action.method,
            headers: action.headers,
            body: JSON.stringify(payload)
          })
            .then(async r => {
              const st = await r.status;
              if (st == 200 || st == 201) {
                this.$bvToast.toast(text + " salvata con successo", {
                  title: "Modifica effettuata",
                  variant: "success",
                  solid: true
                });
                const data = await r.json();

                // get data from employees and vehicles endpoints
                const employees = await fetch(
                  `${process.env.VUE_APP_BACKEND_URL}/employees/`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.access_token}`
                    }
                  }
                ).then(async res => {
                  const data = await res.json();
                  return data.results;
                });
                const vehicles = await fetch(
                  `${process.env.VUE_APP_BACKEND_URL}/vehicles/`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.access_token}`
                    }
                  }
                ).then(async res => {
                  const data = await res.json();
                  return data.results;
                });

                // get data of exercisesheets
                await fetch(
                  `${process.env.VUE_APP_BACKEND_URL}/exercise-sheets/${this.form.fe}/`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.access_token}`
                    }
                  }
                ).then(async res => {
                  data.fe = await res.json();
                });

                data.employees = employees.filter(
                  x => data.employees.indexOf(x.id) > -1
                );
                data.vehicles = vehicles.filter(
                  x => data.vehicles.indexOf(x.id) > -1
                );

                if (this.form.id) {
                  this.$set(this.operations, this.temp_form.index, data);
                  this.temp_form = {
                    index: null,
                    data: {}
                  };
                } else {
                  this.operations.push(data);
                }
                this.$bvModal.hide("modalform");
                this.form = {};
              } else {
                this.$bvToast.toast(`Errore ${st}`, {
                  title: "Errore nel salvataggio",
                  variant: "danger",
                  solid: true
                });
              }
            })
            .catch(e => {
              this.$bvToast.toast(e.status, {
                title: "Errore nel salvataggio",
                variant: "danger",
                solid: true
              });
            })
            .finally(() => (this.$refs.headerwhite.loading = false));
        } else {
          return false;
        }
      });
    },
    handleDelete(index) {
      let route = `${process.env.VUE_APP_BACKEND_URL}/operation-requests/${this.$props.id}/operations`;
      route += `/${this.operations[index].id}/`;

      fetch(route, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.access_token}`
        }
      })
        .then(() => {
          this.$bvToast.toast("Intervento salvato con successo", {
            title: "Modifica effettuata",
            variant: "success",
            solid: true
          });
          this.operations.splice(index, 1);
        })
        .catch(e => {
          this.$bvToast.toast(e.status, {
            title: "Errore nel salvataggio",
            variant: "danger",
            solid: true
          });
        });
    }
  }
};
</script>
