import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Forgot from "../views/Forgot.vue";
import Employees from "../views/Employees.vue";
import Vehicles from "../views/Vehicles.vue";
import Customers from "../views/Customers.vue";
import Stores from "../views/Stores.vue";
import ExerciseSheets from "../views/ExerciseSheets.vue";
import Operations from "../views/Operations.vue";
import OperationsNew from "../views/OperationsNew.vue";
import OperationsDetails from "../views/OperationsDetails.vue";
import AtbCustomers from "../views/AtbCustomers.vue";
import Tankers from "../views/Tankers.vue";
import Interventions from "../views/Interventions.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Home", component: Home },
  {
    path: "/password-reset(/confirm/)?:uid?/:token?",
    name: "Forgot",
    component: Forgot,
    props: true,
  },
  { path: "/customers", name: "Customers", component: Customers },
  { path: "/atb-customers", name: "AtbCustomers", component: AtbCustomers },
  { path: "/tankers", name: "Tankers", component: Tankers },
  {
    path: "/atb-interventions",
    name: "Interventions",
    component: Interventions,
  },
  { path: "/stores", name: "Stores", component: Stores },
  { path: "/operations", name: "Operations", component: Operations },
  { path: "/operations/new", name: "OperationsNew", component: OperationsNew },
  {
    path: "/operations/:id/edit",
    name: "OperationsDetails",
    component: OperationsDetails,
    props: true,
  },
  { path: "/employees", name: "Employees", component: Employees },
  { path: "/vehicles", name: "Vehicles", component: Vehicles },
  {
    path: "/exercise-sheets",
    name: "ExerciseSheets",
    component: ExerciseSheets,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/"];
  const authRequired = !publicPages.includes(to.path);

  const access_token = Vue.$cookies.get("access_token");

  // If the user went on a private route when not logged in
  if (authRequired && !access_token) {
    return next({ path: "/", query: { authError: true } });
  }

  next();
});

export default router;
