<template>
  <b-nav id="vertical-sidebar" vertical>
    <b-nav-item href="/atb-customers" :active="nav == 'atb-customers'" class="nav-item primary-nav">
      Clienti ATB
    </b-nav-item>
    <b-nav-item href="/tankers" :active="nav == 'tankers'" class="nav-item primary-nav">
      Autobotti ATB
    </b-nav-item>
    <b-nav-item href="/atb-interventions" :active="nav == 'interventions'" class="nav-item primary-nav">
      Interventi ATB
    </b-nav-item>

    <div class="nav-separator"></div>

    <b-nav-item href="/customers" :active="nav == 'customers'" class="nav-item secondary-nav">
      Clienti
    </b-nav-item>
    <b-nav-item href="/stores" :active="nav == 'stores'" class="nav-item secondary-nav">
      Punti vendita
    </b-nav-item>
    <b-nav-item href="/operations" :active="nav == 'operations'" class="nav-item secondary-nav">
      Richieste d'intervento
    </b-nav-item>
    <b-nav-item href="/employees" :active="nav == 'employees'" class="nav-item secondary-nav">
      Dipendenti
    </b-nav-item>
    <b-nav-item href="/vehicles" :active="nav == 'vehicles'" class="nav-item secondary-nav">
      Automezzi
    </b-nav-item>
    <b-nav-item href="/exercise-sheets" :active="nav == 'exercisesheets'" class="nav-item secondary-nav">
      Fogli d'esercizio
    </b-nav-item>

    <hr />

    <b-nav-item class="logout" @click="logout('terminata')">
      <b-button variant="light">Logout</b-button>
    </b-nav-item>
  </b-nav>
</template>

<script>
export default {
  name: "AdminSidebar",
  props: ["nav"],
  methods: {
    logout(sessionType) {
      this.$bvToast.toast("Effettua di nuovo il login", {
        title: "Sessione " + sessionType,
        variant: "warning",
        solid: true,
      });
      this.$cookies.remove("access_token");
      localStorage.removeItem("login");
      window.setTimeout(() => (window.location.href = "/"), 1000);
    },
  },
};
</script>
