<template>
  <main>
    <HeaderWhite ref="headerwhite" />
    <b-container id="main-container">
      <b-modal
        id="modalIntervention"
        hide-footer
        size="xl"
        @hidden="clearFormIntervention"
        @close="clearFormIntervention"
        @hide="clearFormIntervention"
      >
        <el-form
          :rules="rules"
          ref="formIntervention"
          :model="formIntervention"
          label-width="120px"
          class="modal-form"
        >
          <span class="asterisk">
            I campi seguiti da <code>*</code> sono obbligatori
          </span>

          <b-row class="filter-row">
            <b-col md="4" sm="12">
              <FormInput
                label="Autobotte"
                name="tanker"
                :model="formIntervention.tanker"
                v-model="formIntervention.tanker"
                :options="tankers"
              />
            </b-col>
            <b-col md="4" sm="12">
              <FormInput
                label="Dipendente"
                name="employee"
                :model="formIntervention.employees"
                v-model="formIntervention.employees"
                :options="employees_list"
                :multiselect="true"
                :clearable="true"
              />
            </b-col>

            <b-col md="4" sm="12">
              <FormInput
                label="Data Intervento"
                name="intervention_date"
                :model="formIntervention.intervention_date"
                v-model="formIntervention.intervention_date"
                :datepicker="true"
                :clearable="true"
                placeholder="Seleziona una data"
              />
            </b-col>
          </b-row>

          <b-row class="filter-row">
            <b-col md="3" sm="12">
              <FormInput
                label="Stato"
                name="status"
                :model="formIntervention.status"
                v-model="formIntervention.status"
                :options="requests_status"
                :clearable="true"
              />
            </b-col>
            <b-col md="3" sm="12">
              <FormInput
                label="Località"
                name="location"
                :model="formIntervention.location"
                v-model="formIntervention.location"
                :clearable="true"
              />
            </b-col>
            <b-col md="3" sm="12">
              <el-form-item label="N. Fattura" prop="invoice_number">
                <el-input
                  placeholder="N.36234872"
                  :model="formIntervention.invoice_number"
                  v-model="formIntervention.invoice_number"
                  type="text"
                  :clearable="true"
                />
              </el-form-item>
            </b-col>
            <b-col md="3" sm="12">
              <el-form-item label="Importo pagato" prop="amount_paid">
                <el-input
                  :model="formIntervention.amount_paid"
                  v-model="formIntervention.amount_paid"
                  type="number"
                  :clearable="true"
                  min="0"
                  step="1"
                  value="0"
                />
              </el-form-item>
            </b-col>
          </b-row>

          <b-row class="filter-row">
            <b-col md="12">
              <FormInput
                label="Descrizione"
                name="description"
                :model="formIntervention.description"
                v-model="formIntervention.description"
                :clearable="true"
                :text="true"
                rows="3"
                max-rows="8"
              />
            </b-col>
          </b-row>

          <b-row class="filter-row">
            <b-col>
              <b-button
                type="button"
                variant="primary"
                @click="onSubmitIntervention"
              >
                Salva
              </b-button>
            </b-col>
          </b-row>
        </el-form>
      </b-modal>
      <div>
        <AdminSidebar nav="interventions" />
        <aside class="_body">
          <b-nav id="header">
            <div class="d-flex">
              <b-button v-b-modal.modalIntervention variant="primary"
                >Nuovo Intervento</b-button
              >
              <b-button
                variant="secondary"
                class="report-pdf"
                @click="handleReportPDF"
                >Genera PDF</b-button
              >
              <b-button
                variant="outline-secondary"
                class="report-pdf"
                @click="handleResetFilters"
                >Cancella filtri</b-button
              >
            </div>
          </b-nav>

          <el-form id="filters">
            <h4>Filter</h4>
            <b-row class="filter-row">
              <b-col class="filter-col" lg="3" sm="12">
                <el-form-item label="ID Intervento" prop="id">
                  <el-input
                    :model="filters.id"
                    v-model="filters.id"
                    type="number"
                    class="no-spin"
                    min="0"
                  />
                </el-form-item>
              </b-col>
              <b-col lg="3" sm="12">
                <FormInput
                  label="Autobotte"
                  name="tanker"
                  :model="filters.tanker"
                  v-model="filters.tanker"
                  :options="tankers"
                  :clearable="true"
                />
              </b-col>
              <b-col class="filter-col" lg="3" sm="12">
                <FormInput
                  label="Dipendente"
                  name="employee"
                  :model="filters.employees"
                  v-model="filters.employees"
                  :options="employees_list"
                  :multiselect="true"
                  :clearable="true"
                />
              </b-col>
              <b-col class="filter-col" lg="3" sm="12">
                <FormInput
                  label="Località"
                  name="location"
                  :model="filters.location"
                  v-model="filters.location"
                />
              </b-col>
            </b-row>

            <b-row class="filter-row">
              <b-col class="filter-col" lg="4" sm="12">
                <FormInput
                  label="Stato"
                  name="status"
                  :model="filters.status"
                  v-model="filters.status"
                  :options="requests_status"
                />
              </b-col>
              <b-col class="filter-col" lg="4" sm="12">
                <FormInput
                  label="Data inizio"
                  name="intervention_date_start"
                  :model="filters.intervention_date_start"
                  v-model="filters.intervention_date_start"
                  :datepicker="true"
                  :clearable="true"
                  placeholder="Seleziona una data"
                />
              </b-col>
              <b-col class="filter-col" lg="4" sm="12">
                <FormInput
                  label="Data fine"
                  name="intervention_date_end"
                  :model="filters.intervention_date_end"
                  v-model="filters.intervention_date_end"
                  :datepicker="true"
                  :clearable="true"
                  placeholder="Seleziona una data"
                />
              </b-col>
            </b-row>

            <b-row class="filter-row">
              <b-col class="filter-col" lg="4" sm="12">
                <el-form-item label="Numero Fattura" prop="invoice_number">
                  <el-input
                    placeholder="N.36234872"
                    :model="filters.invoice_number"
                    v-model="filters.invoice_number"
                    type="text"
                    :clearable="true"
                  />
                </el-form-item>
              </b-col>
              <b-col class="filter-col" lg="4" sm="12">
                <el-form-item prop="min_amount_paid">
                  <label :style="{ color: '#606266' }">
                    Minimo importo pagato
                  </label>
                  <b-form-input
                    id="range-1"
                    :model="filters.min_amount_paid"
                    v-model="filters.min_amount_paid"
                    type="number"
                    value="0"
                    min="0"
                  ></b-form-input>
                </el-form-item>
              </b-col>

              <b-col class="filter-col" lg="4" sm="12">
                <el-form-item prop="max_amount_paid">
                  <label :style="{ color: '#606266' }"
                    >Massimo importo pagato
                  </label>
                  <b-form-input
                    id="range-2"
                    :model="filters.max_amount_paid"
                    v-model="filters.max_amount_paid"
                    type="number"
                    value="0"
                    min="0"
                    debounce="200"
                  ></b-form-input>
                </el-form-item>
              </b-col>
            </b-row>

            <b-row class="filter-row">
              <b-col>
                <FormInput
                  label="Descrizione"
                  name="description"
                  :model="filters.description"
                  v-model="filters.description"
                  :clearable="true"
                  :text="true"
                  rows="3"
                  max-rows="8"
                />
              </b-col>
            </b-row>
          </el-form>

          <b-table
            id="my-table"
            responsive
            hover
            :items="interventions"
            :fields="fields"
            sort-by="id"
            :sort-desc="true"
            :sort-compare="sortCompare"
            :per-page="perPage"
            :current-page="currentPage"
            small
          >
            <template #cell(id)="data">
              <span>{{ data.item.id }}</span>
            </template>
            <template #cell(intervention_date)="data">
              <span>{{ formatDate(data.item.intervention_date) }}</span>
            </template>
            <template #cell(status)="data">
              <el-select
                style="width: 100px !important"
                name="status"
                v-model="data.item.status"
                @change="editIntervention(data.item)"
              >
                <el-option
                  v-for="item in requests_status"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select>
            </template>
            <template #cell(details)="row">
              <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                {{ row.detailsShowing ? "Nascondi" : "Mostra" }} dettagli
              </b-button>
              <b-button style="float: left" @click="editIntervention(row.item)"
                >Edit</b-button
              >
              <b-button
                size="sm"
                variant="danger"
                @click="deleteIntervention(row.item.id)"
              >
                <b-icon-trash />
              </b-button>
            </template>
            <template #row-details="row">
              <b-card id="details">
                <b-row>
                  <b-col cols="12" lg="4">
                    <b>ID Intervento:</b>
                    <span>{{ row.item.id }} </span>
                  </b-col>

                  <b-col cols="12" lg="4">
                    <b>ID Autobotte:</b>
                    <span>{{ row.item.tanker }} </span>
                  </b-col>

                  <b-col cols="12" lg="4">
                    <b>ID Dipendenti:</b>
                    <span>
                      <template>
                        {{ row.item.employees.join(", ") }}
                      </template>
                    </span>
                  </b-col>

                  <!-- Località -->
                  <b-col cols="6" lg="4">
                    <b>Località:</b> <span>{{ row.item.location }}</span>
                  </b-col>

                  <!-- Descrizione -->
                  <b-col cols="12" lg="4">
                    <b>Descrizione:</b> <span>{{ row.item.description }}</span>
                  </b-col>

                  <!-- Data Intervento -->
                  <b-col cols="6" lg="4">
                    <b>Data Intervento:</b>
                    <span>{{ row.item.intervention_date }}</span>
                  </b-col>

                  <!-- Stato -->
                  <b-col cols="6" lg="4">
                    <b>Stato:</b>
                    <span>{{
                      row.item.status == "open" ? "Aperto" : "Chiuso"
                    }}</span>
                  </b-col>

                  <!-- Numero Fattura -->
                  <b-col cols="6" lg="4">
                    <b>Numero Fattura:</b>
                    <span>{{ row.item.invoice_number }}</span>
                  </b-col>

                  <!-- Importo -->
                  <b-col cols="6" lg="4">
                    <b>Importo:</b>
                    <span
                      >{{ row.item.amount_paid }}
                      {{ row.item.amount_paid ? "€" : "" }}</span
                    >
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>

          <div id="pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </div>
        </aside>
      </div>
    </b-container>
  </main>
</template>

<script>
import HeaderWhite from "@/components/header-white.vue";
import AdminSidebar from "@/components/admin-sidebar.vue";
import FormInput from "@/components/form-input.vue";

export default {
  name: "Interventions",
  components: {
    HeaderWhite,
    AdminSidebar,
    FormInput,
  },
  data() {
    return {
      rules: {
        tanker: [
          { required: true, message: "Il campo Autobotte è obbligatorio" },
        ],
        employees: [
          { required: true, message: "Il campo Dipendente è obbligatorio" },
        ],
        description: [
          { required: true, message: "Il campo Descrizione è obbligatorio" },
        ],
        intervention_date: [
          { required: true, message: "La data dell'intervento è obbligatoria" },
        ],
        status: [{ required: true, message: "Il campo Stato è obbligatorio" }],
        invoice_number: [{ message: "Il numero della fattura è obbligatorio" }],
        amount_paid: [
          {
            message: "L'importo pagato è obbligatorio",
          },
        ],
      },
      interventions: [],
      tankers: [],
      employees_list: [],
      formIntervention: {
        tanker: "",
        employees: "",
        intervention_date: "",
        status: "",
        location: "",
        invoice_number: "",
        amount_paid: null,
        description: "",
      },
      archived: false,
      fields: [
        { key: "show_details", label: "" },
        { key: "id", label: "ID", sortable: true },
        {
          key: "location",
          label: "Località",
          sortable: true,
        },
        {
          key: "intervention_date",
          label: "Data Intervento",
          sortable: true,
        },
        { key: "status", label: "Stato", sortable: false },
        { key: "invoice_number", label: "N. Fattura", sortable: false },
        { key: "amount_paid", label: "Importo", sortable: false },
        { key: "details", label: "", sortable: false },
      ],
      requests_status: [
        { value: "open", text: "Open" },
        { value: "closed", text: "Closed" },
      ],
      filters: { min_amount_paid: 0, max_amount_paid: 0 },
      currentPage: 1,
      perPage: 10,
      count: 0,
    };
  },
  watch: {
    filters: {
      handler() {
        this.filterBy();
      },
      deep: true,
    },
  },
  mounted() {
    this.archived = this.$route.query.archived !== undefined;
    this.access_token = this.$cookies.get("access_token");
    const query = this.$route.query;
    this.currentPage = parseInt(query["p"]) || 1;

    if (query["id"]) this.filters.tanker = query["id"];
    if (query["tanker"]) this.filters.tanker = query["tanker"];
    if (query["employees"]) this.filters.employee = query["employees"];
    if (query["status"]) this.filters.status = query["status"];
    if (query["intervention_date_start"])
      this.filters.intervention_date_start = query["intervention_date_start"];
    if (query["intervention_date_end"])
      this.filters.intervention_date_end = query["intervention_date_end"];
    if (query["description"]) this.filters.description = query["description"];
    if (query["location"]) this.filters.description = query["location"];
    if (query["min_amount_paid"])
      this.filters.description = query["min_amount_paid"];
    if (query["max_amount_paid"])
      this.filters.description = query["max_amount_paid"];
    if (query["invoice_number"])
      this.filters.description = query["invoice_number"];

    this.getData(false);
    setTimeout(() => {
      this.$set(this.$data, "currentPage", this.currentPage);
    }, 1000);

    fetch(`${process.env.VUE_APP_BACKEND_URL}/tankers/`, {
      method: "GET",
      headers: { Authorization: `Bearer ${this.access_token}` },
    })
      .then((response) => response.json())
      .then((data) => {
        this.tankers = data.results.map((tanker) => ({
          text: `${tanker.atbcustomer.corporate_name} - ${tanker.location}`,
          value: tanker.id,
        }));
      });

    // Fetch employees
    fetch(`${process.env.VUE_APP_BACKEND_URL}/employees/`, {
      method: "GET",
      headers: { Authorization: `Bearer ${this.access_token}` },
    })
      .then((response) => response.json())
      .then((data) => {
        this.employees_list = data.results.map((employee) => ({
          text: `${employee.name} - ${employee.city} - ${employee.telephone_number}`,
          value: employee.id,
        }));
      });

    this.getData(false);
  },
  methods: {
    async handleReportPDF() {
      const url = `${process.env.VUE_APP_BACKEND_URL}/atb-interventions/pdf/?limit=1000`;
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token}`,
        },
        body: JSON.stringify(this.filters),
      })
        .then(async (r) => ({
          filename: `report_interventi_${new Date().toLocaleDateString()}.pdf`,
          blob: await r.blob(),
          headers: await r.headers,
        }))
        .then((obj) => {
          const newBlob = new Blob([obj.blob], { type: "application/pdf" });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement("a");
            link.href = objUrl;
            link.download = obj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
          }
        })
        .catch((e) => {
          this.$bvToast.toast(e.status, {
            title: "Errore nella generazione del PDF",
            variant: "danger",
            solid: true,
          });
        });
    },
    filterBy() {
      // Ensure that filters are formatted correctly
      if (this.filters.intervention_date_start) {
        this.filters.intervention_date_start = this.$moment(
          this.filters.intervention_date_start
        ).format("YYYY-MM-DD");
      } else if (this.filters.intervention_date_start === null) {
        delete this.filters.intervention_date_start;
      }

      if (this.filters.intervention_date_end) {
        this.filters.intervention_date_end = this.$moment(
          this.filters.intervention_date_end
        ).format("YYYY-MM-DD");
      } else if (this.filters.intervention_date_end === null) {
        delete this.filters.intervention_date_end;
      }

      this.cleanFilters();
      this.getData(true);
    },
    getData(have_filter) {
      let route = `${process.env.VUE_APP_BACKEND_URL}/atb-interventions/`;
      let body = null;
      if (have_filter) {
        route += "filter/";
        body = JSON.stringify(this.filters);
      }
      route += "?limit=1000";
      fetch(route, {
        method: have_filter ? "POST" : "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
        body,
      }).then(async (response) => {
        const data = await response.json();
        this.interventions = data.results;
        this.count = data.count;
      });
    },
    clearFormIntervention() {
      this.formIntervention = {
        tanker: "",
        employees: "",
        intervention_date: "",
        status: "",
        invoice_number: "",
        amount_paid: null,
        description: "",
      };
    },
    handleResetFilters() {
      this.filters = { min_amount_paid: 0, max_amount_paid: 0 };
      this.$bvToast.toast(`Filtri cancellati`, {
        title: "Filtri cancellati con successo!",
        variant: "success",
        solid: true,
      });
    },
    onSubmitIntervention() {
      this.$refs.formIntervention.validate(async (valid) => {
        if (valid) {
          if (this.formIntervention.intervention_date) {
            this.formIntervention.intervention_date = this.$moment(
              this.formIntervention.intervention_date
            ).format("YYYY-MM-DD");
          }

          const method = this.formIntervention.id ? "PUT" : "POST";
          let url = `${process.env.VUE_APP_BACKEND_URL}/atb-interventions/`;
          if (method === "PUT") url += `${this.formIntervention.id}/`;

          await fetch(url, {
            method,
            headers: {
              Authorization: `Bearer ${this.access_token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formIntervention),
          });

          this.getData(false);
          this.$bvModal.hide("modalIntervention");
        }
      });
    },
    deleteIntervention(id) {
      const confirmed = confirm(
        "Sei sicuro di voler eliminare questo intervento?"
      );
      if (!confirmed) return;

      fetch(`${process.env.VUE_APP_BACKEND_URL}/atb-interventions/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      }).then(() => {
        this.getData(false);
      });
    },
    editIntervention(intervention) {
      this.formIntervention = { ...intervention };
      this.$bvModal.show("modalIntervention");
    },
    formatDate(date) {
      return this.$moment(date).format("DD/MM/YYYY");
    },
    sortCompare(a, b, key) {
      if (key === "intervention_date") {
        return this.$moment(a[key]) - this.$moment(b[key]);
      }
      return a[key] - b[key];
    },
    seeArchive() {
      this.getData(false);
    },
    cleanFilters() {
      for (const field of Object.keys(this.filters)) {
        if (this.filters[field] === "" || this.filters[field] === null) {
          delete this.filters[field];
        }
      }
    },
    handleFilterTanker(val) {
      this.filters.tanker = val;
    },
    handleFilterEmployee(val) {
      this.filters.employees = val;
    },
  },
};
</script>

<style>
.filter-col {
  margin-top: 10px;
}

.report-pdf {
  margin-left: 10px;
  font-weight: bold;
}

/* Hide the spinner and controls for number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
