<template lang="pug">
  main
    HeaderWhite
    b-container#main-container.login
      b-overlay(:show="loading" spinner-small)
        b-card#center-form
          b-form(@submit.stop.prevent="login()")
            img(src="/logo.png" alt="logo")
            b-form-group#form-user
              b-form-input(
                placeholder="inserisci il tuo username" id="username" required
                v-model="form.username"
              )
            b-form-group.form-passw
              b-form-input(
                placeholder="inserisci la tua password" id="passw" required
                v-model="form.password" :type="(password_type)?'text':'password'"
              )
            b-form-checkbox(v-model="password_type") Visualizza password
            b-form-group
              b-button(variant="secondary" size="lg" type="submit") Accedi
          a(href="/password-reset" style="text-align:center;display:block")
            | Password dimenticata
</template>

<script>
import HeaderWhite from "@/components/header-white.vue";

export default {
  name: "AdminLogin",
  components: {
    HeaderWhite,
  },
  data() {
    return {
      loading: false,
      password_type: false,
      form: {
        username: "",
        password: "",
      },
    };
  },
  mounted() {
    const authError = this.$route.query.authError;
    if (authError) {
      this.$bvToast.toast("Devi essere un amministratore per accedere", {
        title: "Errore di autenticazione",
        variant: "danger",
        solid: true,
      });
    }
  },
  created() {
    const access_token = this.$cookies.get("access_token");
    if (access_token) window.location.href = "/exercise-sheets";
  },
  methods: {
    async login() {
      this.loading = true;
      fetch(`${process.env.VUE_APP_BACKEND_URL}/auth/login/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.form),
      })
        .then(async (response) => {
          const data = await response.json();
          const datakeys = Object.keys(data);

          if (datakeys.indexOf("access_token") > -1) {
            // save access_token
            this.$cookies.set("access_token", data.access_token);

            // save login credentials for later
            localStorage.setItem("login", JSON.stringify(this.form));

            this.$bvToast.toast("Sarai presto reindirizzato alla pagina", {
              title: "Login effettuato",
              variant: "success",
              solid: true,
            });
            setTimeout(() => {
              window.location.href = "/exercise-sheets";
            }, 2000);
          } else {
            this.errormsg(await response.status);
          }
        })
        .catch((e) => {
          this.errormsg(e.status);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    errormsg(status) {
      let text = "";
      switch (status) {
        case 404:
        case 400:
          text = "L'utente non è un'amministratore o non esiste";
          break;
        case 403:
          text = "L'utente non ha i permessi di amministratore";
          break;
        default:
          text = status;
      }
      this.$bvToast.toast(text, {
        title: "Errore nel login",
        variant: "danger",
        solid: true,
      });
    },
  },
};
</script>
